import React from 'react'
import { Form, Select } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const ReferenceGenomeField = ({
  index,
  isExisting,
  balsamicReferenceGenomes,
  form,
  caseName,
}) => {
  return (
    <Form.Item
      label="Reference genome"
      name={[index, 'reference_genome', 'value']}
      rules={[
        {
          required: !isExisting,
          validator: () => serverValidator('reference_genome', form, caseName, index),
        },
      ]}
    >
      <Select style={{ width: 150 }} disabled={isExisting}>
        {balsamicReferenceGenomes.map(({ text, value }) => (
          <Select.Option key={value} value={value}>
            {text}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
