import React from 'react'
import { Form, Select } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const SourceField = ({ index, prefillConditionalFields, options, form, caseName }) => {
  return (
    <Form.Item
      label="Source"
      name={[index, 'source', 'value']}
      rules={[
        {
          required: true,
          validator: () => serverValidator('source', form, caseName, index),
        },
      ]}
      required
    >
      <Select style={{ width: 168 }} onChange={() => prefillConditionalFields()}>
        {options?.sources?.analysis?.map((panel) => (
          <Select.Option key={panel} value={panel}>
            {panel}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
