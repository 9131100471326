import React from 'react'
import { Form, Input } from 'antd'

export const OtherOrganismField = ({ index, isOtherOrganism }) => {
  // Add serverValidator
  return (
    <Form.Item
      label={' Other organism'}
      name={[index, 'organism_other', 'value']}
      rules={[{ required: !isOtherOrganism }]}
      required={!isOtherOrganism}
    >
      <Input style={{ width: 150 }} />
    </Form.Item>
  )
}
