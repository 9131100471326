import React from 'react'
import { Form, Select, Tooltip } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const PhenotypeTermsField = ({ index, form, caseName }) => {
  return (
    <Tooltip title="Phenotype terms">
      <Form.Item
        label="Ph. terms"
        name={[index, 'phenotype_terms', 'value']}
        rules={[
          {
            validator: () => serverValidator('phenotype_groups', form, caseName, index),
          },
        ]}
      >
        <Select mode="tags" style={{ width: 210 }} />
      </Form.Item>
    </Tooltip>
  )
}
