import React from 'react'
import { Form, Select } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const GenePanelsField = ({ caseName, options, form }) => {
  return (
    <Form.Item
      label="Gene panels"
      name={[caseName, 'panels', 'value']}
      rules={[
        { required: true },
        {
          validator: () => serverValidator('panels', form, caseName, null),
        },
      ]}
    >
      <Select mode="multiple" allowClear style={{ width: 280 }}>
        {options?.panels?.map((panel) => (
          <Select.Option key={panel} value={panel}>
            {panel}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
