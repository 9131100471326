import React from 'react'
import { Form, Select } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const ControlField = ({ index, isExisting, controls, form, caseName }) => {
  return (
    <Form.Item
      label="Control"
      name={[index, 'control', 'value']}
      rules={[
        {
          validator: () => serverValidator('control', form, caseName, index),
        },
      ]}
    >
      <Select style={{ width: 90 }} disabled={isExisting}>
        {controls.map((control) => (
          <Select.Option key={control} value={control}>
            {control}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
