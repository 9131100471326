import React from 'react'
import { Form, Select } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const MotherField = ({ index, parentsSamples, sampleId, form, caseName }) => {
  return (
    <Form.Item
      label="Mother"
      name={[index, 'mother', 'value']}
      rules={[
        {
          validator: () => serverValidator('mother', form, caseName, index),
        },
      ]}
    >
      <Select style={{ width: 182 }} allowClear>
        {parentsSamples?.mother
          ?.filter((sample) => sample.name !== sampleId)
          .map(({ name }) => (
            <Select.Option key={name} value={name}>
              {name}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  )
}
