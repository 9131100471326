import React from 'react'
import { Form, Select, Tooltip } from 'antd'
// Add serverValidator
export const PreProcessingMethodField = ({ index, preprocessingMethods }) => {
  return (
    <Tooltip title={'Pre-processing method'}>
      <Form.Item
        label="Pre-processing"
        name={[index, 'pre_processing_method', 'value']}
        rules={[{ required: true }]}
        required
      >
        <Select style={{ width: 260 }}>
          {preprocessingMethods.map((method) => (
            <Select.Option key={method}>{method}</Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Tooltip>
  )
}
