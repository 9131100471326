import React from 'react'
import { Form, InputNumber, Tooltip } from 'antd'
// Add serverValidator
export const ConcentrationSampleNGField = ({ index }) => {
  return (
    <Tooltip title="Concentration (ng/uL)">
      <Form.Item label="ng/uL" name={[index, 'concentration_sample', 'value']}>
        <InputNumber style={{ width: 60 }} />
      </Form.Item>
    </Tooltip>
  )
}
