import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Alert, Button, Form, Row, Select } from 'antd'
import styles from '../../pages/OrderForm/OrderForm.module.css'
import { PlusOutlined } from '@ant-design/icons'
import { getExistingCases } from '../../services/StatusDbApi'
import { buildExistingFamily } from '../../pages/OrderForm/helpers'
import { CaseForm } from './CaseForm'
import { Case } from '../../services/interfaces'
import { UserContext } from '../../services/contexts/userContext'
import { ErrorNotification } from '../../services/helpers/helpers'
import { supportSystemURL } from '../../services/helpers/constants'

export const CaseFormList = ({
  selectedAnalysisType,
  form,
  options,
  customer,
  validationError,
  skipReceptionControl,
  validatingOrder,
  applicationTags,
}) => {
  const [existingCases, setExistingCases] = useState<Partial<Case[]>>([])
  const userContext = useContext(UserContext)
  const [errors, setErrors] = useState<any[]>([])

  useEffect(() => {
    const cases = form.getFieldValue('cases')
    if (!validatingOrder && cases) {
      form
        .validateFields()
        .then(() => setErrors([]))
        .catch((errors) => {
          setErrors(errors)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatingOrder])

  if (validatingOrder) {
    return null
  }

  return (
    <Form.List name={'cases'}>
      {(cases, { add, remove }) => {
        return (
          <>
            <Row className={styles.formRow}>
              <Button
                onClick={() => add()}
                icon={<PlusOutlined />}
                type={'primary'}
                style={{ marginBottom: 5 }}
              >
                Case
              </Button>
              Add existing case:{' '}
              <Select
                showSearch
                value={null}
                placeholder="Search and select to add case"
                onSearch={(searchInput) => {
                  if (searchInput.length > 3) {
                    getExistingCases(userContext, customer, searchInput, selectedAnalysisType)
                      .then(({ families }) => setExistingCases(() => families))
                      .catch(() =>
                        ErrorNotification(
                          'Could not search for cases',
                          <div>
                            Something is wrong with the database. Open a ticket in the{' '}
                            <a href={`${supportSystemURL}home`}>support system</a> if the error
                            persists
                          </div>
                        )
                      )
                  }
                }}
                onChange={(caseName) =>
                  add(
                    buildExistingFamily(
                      existingCases.find((caseId) => caseId?.internal_id === caseName)
                    )
                  )
                }
                optionFilterProp={'label'}
                options={
                  existingCases?.map((existingCase) => {
                    return {
                      label: existingCase?.name,
                      value: existingCase?.internal_id,
                    }
                  }) || []
                }
              />
            </Row>
            {validationError?.hasError && (
              <>
                {validationError?.messages?.map((message) => (
                  <Alert key={message} message={message} type="error" />
                ))}
              </>
            )}
            {cases.map(({ key, name }) => (
              <CaseForm
                key={key}
                caseName={name}
                remove={remove}
                options={options}
                form={form}
                customer={customer}
                analysisType={selectedAnalysisType}
                skipReceptionControl={skipReceptionControl}
                errors={errors}
                applicationTags={applicationTags}
              />
            ))}
            {cases?.length > 5 && (
              <Button
                onClick={() => add()}
                icon={<PlusOutlined />}
                type={'primary'}
                style={{ marginTop: 5 }}
              >
                Case
              </Button>
            )}
          </>
        )
      }}
    </Form.List>
  )
}
