import React from 'react'
import { Form, Input } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const CommentField = ({ index, form, caseName }) => {
  return (
    <Form.Item
      label="Comment"
      name={[index, 'comment', 'value']}
      rules={[
        {
          validator: () => serverValidator('comment', form, caseName, index),
        },
      ]}
    >
      <Input style={{ width: 310 }} />
    </Form.Item>
  )
}
