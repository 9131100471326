import React from 'react'
import { Form, InputNumber, Tooltip } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const ConcentrationField = ({ index, skipReceptionControl, form, caseName }) => {
  return (
    <Tooltip title={'Concentration (ng/μL)'}>
      <Form.Item
        label="Concentration (ng/μL)"
        name={[index, 'concentration_ng_ul', 'value']}
        required={skipReceptionControl}
        rules={[
          {
            required: skipReceptionControl,
            validator: () => serverValidator('concentration_ng_ul', form, caseName, index),
          },
        ]}
      >
        <InputNumber style={{ width: 65 }} />
      </Form.Item>
    </Tooltip>
  )
}
