import React from 'react'
import styles from '../Forms.module.css'
import { Divider, Row } from 'antd'
import { SampleNameField } from '../Fields/SampleNameField'
import { ControlField } from '../Fields/ControlField'
import { PriorityField } from '../Fields/PriorityField'
import { SaveButton } from '../Fields/SaveButton'
import { ApplicationField } from '../Fields/ApplicationField'
import { IndexField } from '../Fields/IndexField'
import { IndexNumberField } from '../Fields/IndexNumberField'
import { CommentField } from '../Fields/CommentField'
import { PoolField } from '../Fields/PoolField'
import { PoolConcentrationField } from '../Fields/PoolConcentrationField'
import { ContainerField } from '../Fields/ContainerField'
import { ContainerNameField } from '../Fields/ContainerNameField'
import { WellPositionField } from '../Fields/WellPositionField'
import { VolumeField } from '../Fields/VolumeField'
import { DeleteButton } from '../Fields/DeleteButton'
import { ConcentrationSampleNMField } from '../Fields/ConcentrationSampleNMField'

export const RmlFluffyForm = ({
  index,
  setSampleId,
  analysisType,
  controls,
  priorities,
  validateSample,
  setApplication,
  setApplicationObj,
  skipReceptionControl,
  userContext,
  applicationTags,
  form,
  prefillConditionalFields,
  setContainerNameRequirement,
  getAvailableContainersType,
  hasContainer,
  containerNameRequirement,
  setContainerName,
  isPlate,
  plateLayout,
  remove,
  sampleId,
  indexSequences,
  indexSequence,
  hasIndex,
}) => {
  return (
    <>
      <Row className={styles.row}>
        <SampleNameField
          index={index}
          isExisting={null}
          setSampleId={setSampleId}
          buildParentsObject={null}
          form={form}
          caseName={null}
        />
        <ControlField
          index={index}
          isExisting={null}
          controls={controls}
          form={form}
          caseName={null}
        />
        <PriorityField index={index} priorities={priorities} isExisting={null} />
        <SaveButton saveValidate={validateSample} />
      </Row>
      <Row className={styles.row}>
        <ApplicationField
          index={index}
          isExisting={null}
          setApplication={setApplication}
          setApplicationObj={setApplicationObj}
          skipReceptionControl={skipReceptionControl}
          userContext={userContext}
          applicationTags={applicationTags}
          form={form}
          caseName={null}
        />
      </Row>
      <Row className={styles.row}>
        <IndexField
          index={index}
          prefillConditionalFields={prefillConditionalFields}
          indexSequences={indexSequences}
        />
        {hasIndex && (
          <IndexNumberField
            index={index}
            prefillConditionalFields={prefillConditionalFields}
            indexSequence={indexSequence}
          />
        )}
      </Row>
      <Row className={styles.row}>
        <CommentField index={index} form={form} caseName={null} />
        <PoolField index={index} />
        <PoolConcentrationField index={index} />
      </Row>
      <Divider style={{ marginTop: '-2px' }} />

      <Row className={styles.row}>
        <ContainerField
          index={index}
          prefillConditionalFields={prefillConditionalFields}
          setContainerNameRequirement={setContainerNameRequirement}
          getAvailableContainersType={getAvailableContainersType}
          analysisType={analysisType}
          required={false}
          form={form}
          caseName={null}
        />
        {hasContainer && (
          <>
            <ContainerNameField
              index={index}
              containerNameRequirement={containerNameRequirement}
              setContainerName={setContainerName}
              isRML={true}
              form={form}
              caseName={null}
            />
            {isPlate && (
              <WellPositionField
                index={index}
                plateLayout={plateLayout}
                isRML={true}
                form={form}
                caseName={null}
              />
            )}
            <VolumeField index={index} form={form} caseName={null} />
            <ConcentrationSampleNMField index={index} />
          </>
        )}
      </Row>
      <DeleteButton remove={remove} index={index} sampleId={sampleId} />
    </>
  )
}
