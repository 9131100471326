import React from 'react'
import { Form, Input } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const CaseNameField = ({ caseName, isExisting, form }) => {
  return (
    <Form.Item
      label="Case name"
      name={[caseName, 'name', 'value']}
      rules={[
        { required: true },
        {
          validator: () => serverValidator('name', form, caseName, null),
        },
      ]}
    >
      <Input minLength={2} disabled={isExisting} style={{ width: 240 }} />
    </Form.Item>
  )
}
