import React from 'react'
import { Form, Select, Tooltip } from 'antd'
// Add serverValidator
export const PrimerField = ({ index, primers }) => {
  return (
    <Tooltip title={'Sequencing Platform and Primer Scheme'}>
      <Form.Item
        label="Platform/Primer"
        name={[index, 'primer', 'value']}
        rules={[{ required: true }]}
        required
      >
        <Select style={{ width: 180 }}>
          {primers.map((primer) => (
            <Select.Option key={primer}>{primer}</Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Tooltip>
  )
}
