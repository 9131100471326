export interface ClinicalCookies {
  acceptedCookiePolicy: true
}

export interface User {
  email: string | null
  name: string | null
  is_admin: boolean
  id: number | null
  customers: any[]
}

export interface Customer {
  customer: string
  priority: string
}

export interface Case {
  internal_id?: string
  name: string
  cohorts: string[]
  panels: string[]
  samples?: Sample[]
  priority: string
}

export interface Analyses {
  id: string
  family_id: string
  cleaned_at: string | null
  completed_at: string
  created_at: string
  delivery_report_created_at: string | null
  is_primary: boolean
  pipeline: string
  pipeline_version: string
  started_at: string
  upload_started_at: string
  uploaded_at: string
}

export interface Order {
  comment: string | null
  customer: string | null
  name: string | null
  samples?: Sample[]
  families?: Case[]
}

export interface Sample {
  name: string
  internal_id?: string
  data_analysis: string
  data_delivery: string
  application: string
  mother?: string
  father?: string
  family_name: string
  case_internal_id: null
  require_qc_ok: true
  sex: Sex
  source: string
  priority: string
  formalin_fixation_time: number
  post_formalin_fixation_time: number
  tissue_block_size: string
  cohorts: string[]
  phenotype_groups: string[]
  phenotype_terms: string[]
  subject_id: string
  synopsis: string
  age_at_sampling: number
  comment: string
  control: string
  elution_buffer: string
  container: string
  container_name: string
  well_position: string
  volume: number
  concentration_ng_ul: number
  panels: string[]
  status: string
  tumour?: boolean
  reference_genome?: string
  customer: any
  index: any
  index_number: any
}

export enum Sex {
  female = 'female',
  male = 'male',
  unknown = 'unknown',
}

export interface CarrierStatus {
  status: string
  badgeColor: 'default' | 'warning' | 'success'
}

export interface Parents {
  mother: any[]
  father: any[]
}
