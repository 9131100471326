import React from 'react'
import { Form, Select } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const WellPositionField = ({ index, plateLayout, isRML, form, caseName }) => {
  return (
    <Form.Item
      label="Well"
      name={[index, isRML ? 'well_position_rml' : 'well_position', 'value']} // Check if the value is render in the form
      rules={[
        {
          required: true,
          validator: () => serverValidator('well_position', form, caseName, index),
        },
      ]}
    >
      <Select style={{ width: 70 }}>
        {plateLayout().map((well) => (
          <Select.Option key={well} value={well}>
            {well}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
