import React from 'react'
import { Form, Input } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const SourceCommentField = ({ index, form, caseName }) => {
  return (
    <Form.Item
      label={'Which other source?'}
      name={[index, 'source_comment', 'value']}
      rules={[
        {
          required: true,
          validator: () => serverValidator('source_comment', form, caseName, index),
        },
      ]}
    >
      <Input placeholder={'Which other source?'} style={{ width: 245 }} />
    </Form.Item>
  )
}
