import React from 'react'
import { Form, Select } from 'antd'
// Add serverValidator
export const RegionField = ({ index, regions }) => {
  return (
    <Form.Item
      label="Region"
      name={[index, 'region', 'value']}
      rules={[{ required: true }]}
      required
    >
      <Select style={{ width: 140 }}>
        {regions.map((region) => (
          <Select.Option key={region}>{region}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
