import React from 'react'
import { Form, Input } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const SampleNameField = ({
  index,
  isExisting,
  setSampleId,
  buildParentsObject,
  form,
  caseName,
}) => {
  return (
    <Form.Item
      label="Name"
      name={[index, 'name', 'value']}
      rules={[
        {
          required: true,
          validator: () => serverValidator('name', form, caseName, index),
        },
      ]}
    >
      <Input
        style={{ width: 200 }}
        disabled={isExisting}
        onChange={(e) => {
          setSampleId(e.target.value)
          buildParentsObject?.(e.target.value)
        }}
      />
    </Form.Item>
  )
}
