import React from 'react'
import { Form, Select } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const BaitSetField = ({ index, isExisting, options, form, caseName }) => {
  return (
    <Form.Item
      label="Bait set"
      name={[index, 'capture_kit', 'value']}
      rules={[
        {
          required: !isExisting,
          validator: () => serverValidator('capture_kit', form, caseName, index),
        },
      ]}
    >
      <Select style={{ width: 250 }} disabled={isExisting}>
        {options?.beds?.map((bed) => (
          <Select.Option key={bed} value={bed}>
            {bed}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
