import React from 'react'
import { Form, Select, Tooltip } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const ElutionBufferField = ({
  index,
  isBufferRequired,
  application,
  buffers,
  form,
  caseName,
}) => {
  return (
    <Tooltip title={'Elution buffer'}>
      <Form.Item
        label="Buffer"
        name={[index, 'elution_buffer', 'value']}
        rules={[
          { required: isBufferRequired(application) },
          {
            validator: () => serverValidator('elution_buffer', form, caseName, index),
          },
        ]}
        required={isBufferRequired(application)}
      >
        <Select style={{ width: 230 }}>
          {buffers.map((buffer) => (
            <Select.Option key={buffer}>{buffer}</Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Tooltip>
  )
}
