import React from 'react'
import { Form, Select } from 'antd'

export const CohortsField = ({ caseName, isExisting }) => {
  {
    /* Dose it need the 'value'? */
  }
  return (
    <Form.Item label="Cohorts" name={[caseName, 'cohorts', 'value']}>
      <Select disabled={isExisting} mode="tags" style={{ width: 270 }} />
    </Form.Item>
  )
}
