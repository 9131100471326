import React from 'react'
import { Form, Select } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const SexField = ({
  index,
  isExisting,
  buildParentsObject,
  sampleId,
  Sex,
  form,
  caseName,
}) => {
  return (
    <Form.Item
      label="Sex"
      name={[index, 'sex', 'value']}
      rules={[
        {
          required: !isExisting,
          validator: () => serverValidator('sex', form, caseName, index),
        },
      ]}
      required={!isExisting}
    >
      <Select
        disabled={isExisting}
        style={{ width: 100 }}
        onChange={() => {
          buildParentsObject(sampleId)
        }}
      >
        {Object.keys(Sex).map((sex) => (
          <Select.Option key={sex} value={sex}>
            {sex}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
