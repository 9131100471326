import { serverValidator } from '../../../pages/OrderForm/helpers'
import { Form, Input } from 'antd'
import CopyButton from './CopyButton'
import React from 'react'

export const SubjectIdField = ({ subjectId, index, required, caseName, isExisting, form }) => {
  return (
    <>
      <Form.Item
        label="Subject ID"
        name={[index, 'subject_id', 'value']}
        rules={[
          {
            required: required,
            message: 'Subject ID is required',
          },
          {
            validator: () => serverValidator('subject_id', form, caseName, index),
          },
        ]}
      >
        <Input style={{ width: 172 }} disabled={isExisting} />
      </Form.Item>
      {isExisting && <CopyButton text={subjectId} />}
    </>
  )
}
