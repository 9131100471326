import React from 'react'
import { Form, InputNumber } from 'antd'
// Add serverValidator
export const PoolConcentrationField = ({ index }) => {
  return (
    <Form.Item label="Pool concentration" name={[index, 'concentration', 'value']} required>
      <InputNumber style={{ width: 55 }} />
    </Form.Item>
  )
}
