import React from 'react'
import { Form, Input, Tooltip } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const AgeField = ({ index, form, caseName }) => {
  return (
    <Tooltip title="Age at sampling">
      <Form.Item
        label="Age"
        name={[index, 'age_at_sampling', 'value']}
        rules={[
          {
            validator: () => serverValidator('age_at_sampling', form, caseName, index),
          },
        ]}
      >
        <Input style={{ width: 47 }} />
      </Form.Item>
    </Tooltip>
  )
}
