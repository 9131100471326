import React, { useContext } from 'react'
import { Modal, Tag, Divider, Form, Checkbox, Typography, Tooltip } from 'antd'
import { InfoCircleTwoTone } from '@ant-design/icons'
import { carrierStatus, isBalsamic, isMIPDNA, priorities } from 'services/helpers/constants'
import { isSampleOld, submitOrder } from 'pages/OrderForm/helpers'
import { UserContext } from 'services/contexts/userContext'

const { Text } = Typography

export const OrderDetailsModal = ({
  orderData,
  isConfirmationModalOpen,
  isSubmittingOrder,
  setIsConfirmationModalOpen,
  setIsSubmittingOrder,
  selectedAnalysisType,
  form,
}) => {
  const userContext = useContext(UserContext)

  const renderSample = (sample) => {
    const priorityTag = priorities.find((priority) => priority.value === sample?.priority)
    const carrierTag = carrierStatus.find((carrier) => carrier.status === sample.status)

    return (
      <div key={sample.name} style={{ marginBottom: '8px', display: 'flex' }}>
        <div style={{ flex: '0 0 40%', display: 'flex' }}>
          <Text strong style={{ width: '150px', fontSize: '14px' }}>
            {sample.name}
          </Text>
          <Tag style={{ height: 'fit-content', marginLeft: '10px' }}>{sample.application}</Tag>
        </div>

        {sample?.priority && (
          <div style={{ flex: '0 0 15%' }}>
            <Tag color={priorityTag?.tagColor}>{priorityTag?.text || sample?.priority}</Tag>
          </div>
        )}

        {selectedAnalysisType && isMIPDNA(selectedAnalysisType) && sample.status && (
          <div style={{ flex: '0 0 15%' }}>
            <Tag color={carrierTag?.badgeColor || 'default'}>{sample.status}</Tag>
          </div>
        )}

        {selectedAnalysisType && isBalsamic(selectedAnalysisType) && sample.tumour && (
          <div style={{ flex: '0 0 15%' }}>
            <Tag color={'geekblue'}>TUMOUR</Tag>
          </div>
        )}

        {sample?.received_at && isSampleOld(sample.received_at) && (
          <div style={{ flex: '0 0 15%' }}>
            <Tooltip
              title={
                'You have placed an order of old data. Due to the legacy structure of this old data we will have to manually process it. We cannot guarantee fast delivery of such data - If time is of concern please consider asking us about possible re-sequencing of existing material or sending in a new aliquot.'
              }
            >
              <Tag color={'red'}>
                OLD DATA <InfoCircleTwoTone twoToneColor={'red'} />
              </Tag>
            </Tooltip>
          </div>
        )}

        {sample.control && (
          <div>
            <Tag style={{ height: 'fit-content' }}>{sample.control}</Tag>
          </div>
        )}
      </div>
    )
  }
  return (
    <Modal
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: 30 }}>
          {orderData?.name} <Tag>{orderData?.customer}</Tag>
        </div>
      }
      open={isConfirmationModalOpen && !isSubmittingOrder}
      okButtonProps={{ form: 'confirm', htmlType: 'submit' }}
      onCancel={() => setIsConfirmationModalOpen(false)}
      okText={'Submit order'}
      width={800}
    >
      <p>
        {orderData?.cases
          ? orderData.cases.reduce((total, caseItem) => total + (caseItem.samples?.length || 0), 0)
          : orderData?.samples?.length || 0}{' '}
        sample(s)
      </p>

      {orderData?.cases?.map((caseItem) => (
        <div key={caseItem.name} style={{ marginBottom: '16px' }}>
          <div style={{ marginBottom: '12px' }}>
            <Text strong style={{ fontSize: '18px' }}>
              {caseItem.name}
            </Text>
          </div>
          <div style={{ marginLeft: '20px' }}>
            {caseItem.samples?.map((sample) => renderSample(sample))}
            <Divider style={{ margin: '16px 0' }} />
          </div>
        </div>
      ))}

      {!orderData?.cases && (
        <div style={{ marginLeft: '20px' }}>
          {orderData?.samples?.map((sample) => renderSample(sample))}
        </div>
      )}

      <div>
        <Form
          name="confirm"
          scrollToFirstError={true}
          onFinish={() =>
            submitOrder(
              userContext,
              selectedAnalysisType,
              orderData,
              form,
              setIsConfirmationModalOpen,
              setIsSubmittingOrder
            )
          }
        >
          <Form.Item
            valuePropName={'checked'}
            name="requirements"
            required
            rules={[
              {
                required: true,
                transform: (value) => value || undefined,
                type: 'boolean',
                message: 'Please comply with the requirements',
              },
            ]}
          >
            <Checkbox style={{ marginTop: '25px' }}>
              I have read and I comply with the{' '}
              <a target={'_blank'} href={'/sample-requirements'} rel="noreferrer">
                Clinical Genomics Sample requirements
              </a>
            </Checkbox>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}
