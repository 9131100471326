import React from 'react'
import { Form, Select, Tag } from 'antd'
// Add serverValidator
export const OrganismField = ({ index, setIsOtherOrganism, otherOrganism, form, organisms }) => {
  return (
    <Form.Item
      label="Organism"
      rules={[{ required: true }]}
      required
      name={[index, 'organism', 'value']}
    >
      <Select
        style={{ width: 280 }}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option: any) =>
          option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(e) => {
          setIsOtherOrganism(e === otherOrganism)
          form.setFieldValue(
            ['samples', index, 'reference_genome'], // make sure its working
            organisms.find((organism) => organism.internal_id === e)?.reference_genome
          )
        }}
        options={organisms.map(({ internal_id, verified }) => ({
          label: (
            <div>
              {internal_id} <>{verified && <Tag color={'green'}>Verified</Tag>}</>
            </div>
          ),
          value: internal_id,
        }))}
      />
    </Form.Item>
  )
}
