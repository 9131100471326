import React from 'react'
import { Form, Select } from 'antd'
// Add serverValidator
export const PriorityField = ({ index, priorities, isExisting }) => {
  return (
    <Form.Item
      label="Priority"
      name={[index, 'priority', 'value']} // caseName -> index
      rules={[{ required: !isExisting }]}
    >
      <Select disabled={isExisting} style={{ width: 120 }}>
        {priorities.map(({ value, text }) => (
          <Select.Option key={value} value={value}>
            {text}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
