import React from 'react'
import { Form, Input, Tooltip } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const TumourPurityField = ({ index, isExisting, form, caseName }) => {
  return (
    <Tooltip title={'Tumour Purity (%)'}>
      <Form.Item
        label="Purity"
        name={[index, 'tumour_purity', 'value']}
        rules={[
          {
            validator: () => serverValidator('tumour_purity', form, caseName, index),
          },
        ]}
      >
        <Input type={'number'} style={{ width: 70 }} suffix={'%'} disabled={isExisting} />
      </Form.Item>
    </Tooltip>
  )
}
