import React from 'react'
import { Form, InputNumber, Tooltip } from 'antd'
// Add serverValidator
export const ConcentrationSampleNMField = ({ index }) => {
  return (
    <Tooltip title={'Sample concentration (nM)'}>
      <Form.Item label="nM" name={[index, 'concentration_sample', 'value']}>
        <InputNumber style={{ width: 65 }} />
      </Form.Item>
    </Tooltip>
  )
}
