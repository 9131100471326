import React from 'react'
import { Form, Switch } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const TumourField = ({ index, setIsTumour, isExisting, form, caseName }) => {
  return (
    <Form.Item
      label="Is Tumour"
      name={[index, 'tumour', 'value']}
      valuePropName="checked"
      rules={[{ validator: () => serverValidator('tumour', form, caseName, index) }]}
    >
      <Switch onChange={(e) => setIsTumour && setIsTumour(e)} disabled={isExisting} />
    </Form.Item>
  )
}
