import React from 'react'
import styles from '../../../pages/OrderForm/OrderForm.module.css'
import { Button, Row } from 'antd'
import { DeleteTwoTone } from '@ant-design/icons'
import { CaseNameField } from '../Fields/CaseNameField'
import { PriorityField } from '../Fields/PriorityField'
import { SaveButton } from '../Fields/SaveButton'
import { DeletePopConfirm } from 'components/DeletePopConfirm'
import { CohortsField } from '../Fields/CohortsField'
import { SynopsisField } from '../Fields/SynopsisField'
import { GenePanelsField } from '../Fields/GenePanelsField'

export const MIPDNACaseForm = ({
  caseName,
  isExisting,
  priorities,
  validateCase,
  remove,
  options,
  form,
}) => {
  return (
    <>
      <Row className={styles.formRow}>
        <CaseNameField caseName={caseName} isExisting={isExisting} form={form} />
        <GenePanelsField caseName={caseName} options={options} form={form} />
        <PriorityField index={caseName} priorities={priorities} isExisting={isExisting} />
        <SaveButton saveValidate={validateCase} />
        <DeletePopConfirm
          itemType={'case'}
          action={remove}
          itemName={caseName}
          triggerComponent={<Button icon={<DeleteTwoTone />}>Case</Button>}
        />
      </Row>
      <Row className={styles.formRow}>
        <CohortsField caseName={caseName} isExisting={isExisting} />
        <SynopsisField caseName={caseName} isExisting={isExisting} />
      </Row>
    </>
  )
}
