import React from 'react'
import { Form, Select } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const ContainerField = ({
  index,
  prefillConditionalFields,
  setContainerNameRequirement,
  getAvailableContainersType,
  analysisType,
  required,
  form,
  caseName,
}) => {
  return (
    <Form.Item
      label="Container"
      name={[index, 'container', 'value']}
      rules={[
        {
          required: required,
          validator: () => serverValidator('container', form, caseName, index),
        },
      ]}
    >
      <Select
        style={{ width: 170 }}
        onChange={(value) => {
          prefillConditionalFields()
          setContainerNameRequirement(value === '96 well plate')
        }}
      >
        {getAvailableContainersType(analysisType).map((container) => (
          <Select.Option key={container} value={container}>
            {container}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
