import React from 'react'
import { Form, Input } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const CollectionDateField = ({ index, form, caseName }) => {
  return (
    <Form.Item
      label="Collection date"
      name={[index, 'collection_date', 'value']}
      rules={[
        {
          required: true,
        },
        {
          validator: () => serverValidator('collection_date', form, caseName, index),
        },
      ]}
    >
      <Input placeholder="YYYY-MM-DD" />
    </Form.Item>
  )
}
